import { css } from '@emotion/react';
import { graphql, useStaticQuery } from 'gatsby';

import { HeadInner } from '../../components/Head';
import { Layout } from '../../components/layout';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { colors } from '../../theme/colors';
import { StaticImage } from 'gatsby-plugin-image';

export const Head = () => {
  return <HeadInner path='/qr/' title='資料ダウンロード' noindex />;
};

const RequestDocumentPage = () => {
  const data = useStaticQuery<GatsbyTypes.QrLinkQueryQuery>(graphql`
    query QrLinkQuery {
      contentfulQrLink {
        qrLink {
          childMdx {
            body
          }
        }
      }
    }
  `);

  const qrLink = data.contentfulQrLink?.qrLink;

  return (
    <Layout
      header='simple'
      background='simple'
      footer='simple'
      isBottomMenu={false}
    >
      <h2 css={RequestDocumentTitleStyle}>資料ダウンロード</h2>
      <StaticImage
        src='../../images/thumbnail-qr.jpeg'
        alt='資料ダウンロード'
        css={ThumbnailStyle}
      />
      <div css={LinkWrapperStyle}>
        <MDXRenderer>{qrLink?.childMdx?.body || ''}</MDXRenderer>
      </div>
    </Layout>
  );
};

const RequestDocumentTitleStyle = css({
  color: colors.black,
  fontSize: 40,

  '@media(max-width: 1119px)': {
    fontSize: 28,
  },
});

const ThumbnailStyle = css({
  maxWidth: 960,

  '@media(max-width: 1119px)': {
    maxWidth: 640,
  },

  img: {
    padding: 17,
  },
});

const LinkWrapperStyle = css({
  marginBottom: 96,
});

export default RequestDocumentPage;
